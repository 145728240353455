export default [
  {
    id: 'roles/iam.group-manager',
    version: 0,
    title: 'Group Manager',
    description: 'Group Manager can manage groups and group members.',
    permissions: [
      'iam.groups.get-iam-policy',
      'iam.groups.set-iam-policy',
      'iam.groups.update',
      'iam.groups.update-members',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/iam.group-admin',
    version: 0,
    title: 'Group Administrator',
    description:
      'Allows performing CRUD operations on groups and managing group members.',
    permissions: [
      'iam.groups.count',
      'iam.groups.create',
      'iam.groups.delete',
      'iam.groups.get',
      'iam.groups.get-iam-policy',
      'iam.groups.list',
      'iam.groups.list-members',
      'iam.groups.member-count',
      'iam.groups.set-iam-policy',
      'iam.groups.update',
      'iam.groups.update-members',
    ],
    lowest_applicable_resource_types: [
      'hashicorp.resource-manager.organization',
    ],
  },
];
