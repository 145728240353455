/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20231128SyncConfigGitlabFromJSON(json) {
    return Secrets20231128SyncConfigGitlabFromJSONTyped(json, false);
}
export function Secrets20231128SyncConfigGitlabFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'destination': !exists(json, 'destination') ? undefined : json['destination'],
        'groupId': !exists(json, 'group_id') ? undefined : json['group_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'environmentScope': !exists(json, 'environment_scope') ? undefined : json['environment_scope'],
        '_protected': !exists(json, 'protected') ? undefined : json['protected'],
        'raw': !exists(json, 'raw') ? undefined : json['raw'],
    };
}
export function Secrets20231128SyncConfigGitlabToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'destination': value.destination,
        'group_id': value.groupId,
        'project_id': value.projectId,
        'environment_scope': value.environmentScope,
        'protected': value._protected,
        'raw': value.raw,
    };
}
