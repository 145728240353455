import { inject as service } from '@ember/service';

import BaseAbility from './base-ability';
import {
  PREFIX_IAM_INVITATIONS,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_LIST,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service userContext;

  prefix = PREFIX_IAM_INVITATIONS;

  get canCreate() {
    return this.permissions.scopedPermissions?.organization?.includes(
      this.generate(ACTION_CREATE),
    );
  }

  get canDelete() {
    return this.permissions.scopedPermissions?.organization?.includes(
      this.generate(ACTION_DELETE),
    );
  }

  get canList() {
    return (
      !this.userContext.organizationMeta.ssoEnabled &&
      this.permissions.scopedPermissions?.organization?.includes(
        this.generate(ACTION_LIST),
      )
    );
  }
}
