import baseScenario from './user';
import { faker } from '@faker-js/faker';
import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';
import getPlatformContext from '../helpers/get-platform-context';
import { tfcOrgID } from '../helpers/generate-resource-usage';

let options = {
  createBilling: true,
  onDemandBillingMethodDetails: true,
  billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,
  billingAccountStatus: BILLING_ACCOUNT_STATUS.ACTIVE,
  billingRemainingBalance: 'lowBalance',
};

export default function (server) {
  baseScenario(server, options);

  let { organization, project } = getPlatformContext(server);

  let billingAccount = server.schema.findBy('billing-account.billing-account', {
    organizationId: organization.id,
    projectIds: [project.id],
  });

  let statementsToGenerate = faker.number.int({ min: 1, max: 20 });

  let statements = server.createList(
    'billing-account.statement',
    statementsToGenerate,
    {
      billing_account: billingAccount,
    }
  );

  for (let statement in statements) {
    server.createList(
      'billing-account.statement.project.resource',
      faker.number.int({ min: 0, max: 10 }),
      {
        billing_account: billingAccount,
        projectId: project.id,
        statementId: statement.id,
      }
    );
    // Ensure there is always at least one TFC org.
    server.create('billing-account.statement.project.resource', {
      billing_account: billingAccount,
      resourceLink: {
        id: tfcOrgID,
      },
    });
  }

  server.schema
    .where('billing-account.statement', {
      billingAccountId: billingAccount.id,
    })
    .models.forEach((s) => {
      const previousMonthStatement = server.schema.find(
        'billing-account.statement',
        parseInt(s.id) + 1
      );

      if (!previousMonthStatement) return;

      s.update({
        statement_metadata: {
          previous_statement: {
            total: previousMonthStatement.total,
            billing_period_start: previousMonthStatement.billing_period_start,
            billing_period_end: previousMonthStatement.billing_period_end,
            id: previousMonthStatement.id,
          },
        },
      });
    });
}
