/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128MongoDBAtlasSecretDetailsFromJSON, Secrets20231128MongoDBAtlasSecretDetailsToJSON, } from './';
export function SecretServiceCreateMongoDBAtlasRotatingSecretBodyFromJSON(json) {
    return SecretServiceCreateMongoDBAtlasRotatingSecretBodyFromJSONTyped(json, false);
}
export function SecretServiceCreateMongoDBAtlasRotatingSecretBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'secretName': !exists(json, 'secret_name') ? undefined : json['secret_name'],
        'integrationName': !exists(json, 'integration_name') ? undefined : json['integration_name'],
        'rotationPolicyName': !exists(json, 'rotation_policy_name') ? undefined : json['rotation_policy_name'],
        'secretDetails': !exists(json, 'secret_details') ? undefined : Secrets20231128MongoDBAtlasSecretDetailsFromJSON(json['secret_details']),
    };
}
export function SecretServiceCreateMongoDBAtlasRotatingSecretBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'secret_name': value.secretName,
        'integration_name': value.integrationName,
        'rotation_policy_name': value.rotationPolicyName,
        'secret_details': Secrets20231128MongoDBAtlasSecretDetailsToJSON(value.secretDetails),
    };
}
