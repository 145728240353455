export default [
  {
    id: 'roles/owner',
    version: 0,
    title: 'Owner',
    description:
      'Has all admin permissions and the ability to delete the resource, and promote/demote other owners',
    permissions: [
      'test-resource.update',
      'test-resource.create',
      'test-resource.get',
      'test-resource.delete',
      'test-resource.list',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/admin',
    version: 0,
    title: 'Admin',
    description: 'Full access pretty much',
    permissions: [
      'test-resource.update',
      'test-resource.create',
      'test-resource.get',
      'test-resource.delete',
      'test-resource.list',
      'resource-manager.organizations.get-iam-policy',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/contributor',
    version: 0,
    title: 'Contributor',
    description: 'Can do a lot, but not everything',
    permissions: [
      'test-resource.update',
      'test-resource.create',
      'test-resource.get',
      'test-resource.list',
    ],
    lowest_applicable_resource_types: [],
  },
  {
    id: 'roles/viewer',
    version: 0,
    title: 'Viewer',
    description: 'Can view some things',
    permissions: ['test-resource.get', 'test-resource.list'],
    lowest_applicable_resource_types: [],
  },
];
