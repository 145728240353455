/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { Secrets20231128AwsAccessKeysResponseFromJSON, Secrets20231128AwsAccessKeysResponseToJSON, Secrets20231128AwsFederatedWorkloadIdentityResponseFromJSON, Secrets20231128AwsFederatedWorkloadIdentityResponseToJSON, Secrets20231128CapabilityFromJSON, Secrets20231128CapabilityToJSON, Secrets20231128ConfluentStaticCredentialsResponseFromJSON, Secrets20231128ConfluentStaticCredentialsResponseToJSON, Secrets20231128GcpFederatedWorkloadIdentityResponseFromJSON, Secrets20231128GcpFederatedWorkloadIdentityResponseToJSON, Secrets20231128GcpServiceAccountKeyResponseFromJSON, Secrets20231128GcpServiceAccountKeyResponseToJSON, Secrets20231128IntegrationUsageFromJSON, Secrets20231128IntegrationUsageToJSON, Secrets20231128MongoDBAtlasStaticCredentialsResponseFromJSON, Secrets20231128MongoDBAtlasStaticCredentialsResponseToJSON, Secrets20231128TwilioStaticCredentialsResponseFromJSON, Secrets20231128TwilioStaticCredentialsResponseToJSON, } from './';
export function Secrets20231128IntegrationFromJSON(json) {
    return Secrets20231128IntegrationFromJSONTyped(json, false);
}
export function Secrets20231128IntegrationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'updatedById': !exists(json, 'updated_by_id') ? undefined : json['updated_by_id'],
        'usedBy': !exists(json, 'used_by') ? undefined : (mapValues(json['used_by'], Secrets20231128IntegrationUsageFromJSON)),
        'awsFederatedWorkloadIdentity': !exists(json, 'aws_federated_workload_identity') ? undefined : Secrets20231128AwsFederatedWorkloadIdentityResponseFromJSON(json['aws_federated_workload_identity']),
        'gcpFederatedWorkloadIdentity': !exists(json, 'gcp_federated_workload_identity') ? undefined : Secrets20231128GcpFederatedWorkloadIdentityResponseFromJSON(json['gcp_federated_workload_identity']),
        'twilioStaticCredentials': !exists(json, 'twilio_static_credentials') ? undefined : Secrets20231128TwilioStaticCredentialsResponseFromJSON(json['twilio_static_credentials']),
        'mongoDbAtlasStaticCredentials': !exists(json, 'mongo_db_atlas_static_credentials') ? undefined : Secrets20231128MongoDBAtlasStaticCredentialsResponseFromJSON(json['mongo_db_atlas_static_credentials']),
        'awsAccessKeys': !exists(json, 'aws_access_keys') ? undefined : Secrets20231128AwsAccessKeysResponseFromJSON(json['aws_access_keys']),
        'gcpServiceAccountKey': !exists(json, 'gcp_service_account_key') ? undefined : Secrets20231128GcpServiceAccountKeyResponseFromJSON(json['gcp_service_account_key']),
        'gitlabAccessToken': !exists(json, 'gitlab_access_token') ? undefined : json['gitlab_access_token'],
        'confluentStaticCredentials': !exists(json, 'confluent_static_credentials') ? undefined : Secrets20231128ConfluentStaticCredentialsResponseFromJSON(json['confluent_static_credentials']),
        'capabilities': !exists(json, 'capabilities') ? undefined : (json['capabilities'].map(Secrets20231128CapabilityFromJSON)),
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
    };
}
export function Secrets20231128IntegrationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'provider': value.provider,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by_id': value.createdById,
        'updated_by_id': value.updatedById,
        'used_by': value.usedBy === undefined ? undefined : (mapValues(value.usedBy, Secrets20231128IntegrationUsageToJSON)),
        'aws_federated_workload_identity': Secrets20231128AwsFederatedWorkloadIdentityResponseToJSON(value.awsFederatedWorkloadIdentity),
        'gcp_federated_workload_identity': Secrets20231128GcpFederatedWorkloadIdentityResponseToJSON(value.gcpFederatedWorkloadIdentity),
        'twilio_static_credentials': Secrets20231128TwilioStaticCredentialsResponseToJSON(value.twilioStaticCredentials),
        'mongo_db_atlas_static_credentials': Secrets20231128MongoDBAtlasStaticCredentialsResponseToJSON(value.mongoDbAtlasStaticCredentials),
        'aws_access_keys': Secrets20231128AwsAccessKeysResponseToJSON(value.awsAccessKeys),
        'gcp_service_account_key': Secrets20231128GcpServiceAccountKeyResponseToJSON(value.gcpServiceAccountKey),
        'gitlab_access_token': value.gitlabAccessToken,
        'confluent_static_credentials': Secrets20231128ConfluentStaticCredentialsResponseToJSON(value.confluentStaticCredentials),
        'capabilities': value.capabilities === undefined ? undefined : (value.capabilities.map(Secrets20231128CapabilityToJSON)),
        'resource_name': value.resourceName,
        'resource_id': value.resourceId,
    };
}
