import userScenario from './user';
import getPlatformContext from '../helpers/get-platform-context';

let options = {
  createBilling: true,
};

export default function (server) {
  userScenario(server, options);

  let { organization, project } = getPlatformContext(server);

  let billingAccount = server.schema.findBy('billing-account.billing-account', {
    organizationId: organization.id,
    projectIds: [project.id],
  });

  server.create('billing-account.contract', {
    organization,
    billing_account: billingAccount,
  });

  server.create('billing-account.flexible-consumption-billing-method-detail', {
    billing_account: billingAccount,
  });

  let projects = server.createList('resource-manager.project', 99, {
    organization,
    parent: {
      type: 'ORGANIZATION',
      id: organization.id,
    },
  });

  let statements = server.schema.where('billing-account.statement', {
    billingAccountId: billingAccount.id,
  });

  projects.forEach((project) => {
    statements.models.forEach(() => {
      server.create('billing-account.project-overview', {
        id: project.id,
        billing_account: billingAccount,
      });
    });
  });
}
