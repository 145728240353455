import { camelize } from '@ember/string';
import { faker } from '@faker-js/faker';
import { fullPermissions } from '../helpers/permissions';

import userScenario from './user';

import {
  HashicorpCloudWaypointTerraformTFRunState,
  HashicorpCloudWaypointJobStatus as JobStatus,
} from '@clients/cloud-waypoint-service';

import {
  setFakerSeed,
  getFakerSeed,
  createOrUpdateTFRunState,
  generateVariableOptionValue,
} from '../helpers/waypoint.utils';

export default function (server, options = {}) {
  // ensure stable results between refreshes via sessionStorage (new tabs get a new seed, duplicate tabs keep the same seed)
  const seed = getFakerSeed();
  faker.seed(seed || setFakerSeed(faker.number.int(100)));
  console.log('using seed:', seed);

  userScenario(server, {
    createBilling: true,
    billingAccountStatus: 'ACTIVE',
    hasVerifiedEmail: true,
    allowedPermissions: options.allowedPermissions ?? fullPermissions,
  });

  const organization = server.schema.findBy('resource-manager.organization', {
    name: 'default-org',
  });

  const project = server.schema.findBy('resource-manager.project', {
    parent: { type: 'ORGANIZATION', id: organization.id },
  });

  // return; // Uncommit to simulate empty state

  const namespace = server.create('waypoint.namespace', {
    organization,
    project,
  });

  // TFC Org and Token
  const tfcToken = server.create('waypoint.tfc-token');
  // This is intentional — we emit the token into the console for use in manual testing in Mirage mode.
  console.info('Simulated TFC token:', tfcToken.value);

  const expiredToken = server.create('waypoint.tfc-token', 'expired', {
    value: '123',
  });
  console.info('Expired TFC token:', expiredToken.value);

  const expiringToken = server.create('waypoint.tfc-token', 'will-expire', {
    value: '1234',
  });
  console.info('Expiring TFC token (in 20 seconds):', expiringToken.value);

  const insufficientPermissionsToken = server.create('waypoint.tfc-token', {
    value: '321',
  });
  console.info(
    'Insufficient permissions TFC token:',
    insufficientPermissionsToken.value
  );

  const willLosePermissionsToken = server.create('waypoint.tfc-token', {
    value: '4321',
  });
  console.info(
    'Will lose permissions (in 20 seconds) TFC token:',
    willLosePermissionsToken.value
  );

  const tfcOrganization = server.create('waypoint.tfc-organization', {
    name: 'acmecorp',
    tokens: [tfcToken],
  });
  server.create('waypoint.tfc-organization', {
    name: 'betacorp',
    tokens: [tfcToken],
  });
  server.create('waypoint.tfc-organization', 'invalid', {
    name: 'c-corp',
    tokens: [insufficientPermissionsToken],
  });
  server.create('waypoint.tfc-organization', 'will-invalid', {
    name: 'losing-permissions-corp',
    tokens: [willLosePermissionsToken],
  });
  server.create('waypoint.tfc-organization', {
    name: 'e-corp',
    tokens: [expiredToken, expiringToken],
  });

  // return; // Uncommit to simulate inactive state

  const terraformProjects = {
    default: server.create('waypoint.tfc-project', {
      namespace,
      projectId: 'prj-123456',
      name: 'Default Project',
    }),
    alternate: server.create('waypoint.tfc-project', {
      namespace,
      projectId: 'prj-789101',
      name: 'Alternate Project',
    }),
    newest: server.create('waypoint.tfc-project', {
      namespace,
      projectId: 'prj-654321',
      name: 'Newest Project',
    }),
  };

  const nocodeModuleDefinitions = {
    catBlog: server.create('waypoint.nocode-module-definition', 'random', {
      namespace,
      created: new Date(),
      lastUpdated: new Date(),
      name: 'cat-blog',
      tfcOrganization,
      versions: ['1.0.0', '0.0.3', '0.0.1'],
    }),
    goAndPostgres: server.create(
      'waypoint.nocode-module-definition',
      'random',
      {
        namespace,
        created: new Date(),
        lastUpdated: new Date(),
        name: 'go-and-postgres',
        tfcOrganization,
        versions: ['0.0.2', '0.0.1'],
      }
    ),
    rubyOnRails: server.create('waypoint.nocode-module-definition', 'random', {
      namespace,
      created: new Date(),
      lastUpdated: new Date(),
      name: 'ruby-on-rails',
      tfcOrganization,
      versions: ['0.0.5', '0.0.3', '0.0.2', '0.0.1'],
    }),
    nodeJs: server.create('waypoint.nocode-module-definition', 'random', {
      namespace,
      created: new Date(),
      lastUpdated: new Date(),
      name: 'node-js',
      tfcOrganization,
      versions: ['0.0.5'],
    }),
    redis: server.create('waypoint.nocode-module-definition', 'random', {
      namespace,
      created: new Date(),
      lastUpdated: new Date(),
      name: 'redis',
      tfcOrganization,
      versions: ['1.0.0'],
    }),
  };

  const tfModules = Object.values(nocodeModuleDefinitions).reduce(
    (tfModules, nocodeModuleDefinition) => {
      const { versions } = nocodeModuleDefinition;
      const terraformNocodeModules = versions.map((version) =>
        server.create('waypoint.tf-module', {
          nocodeModuleDefinition,
          version,
        })
      );
      const key = camelize(nocodeModuleDefinition.name);
      tfModules[key] = terraformNocodeModules;
      return tfModules;
    },
    {}
  );

  // return; // Uncomment to simulate the empty state

  // TFC Config
  server.create('waypoint.tfc-config', 'valid', {
    namespace,
    token: tfcToken,
    tfcOrganization,
  });

  // Templates
  const templates = [
    server.create('waypoint.application-template', 'cat-blog', {
      namespace,
      terraformNocodeModule: tfModules.catBlog[0],
      terraformCloudWorkspaceDetails:
        faker.helpers.objectValue(terraformProjects),
      // to show pagination on assign actions to template
      actionConfigs: server.createList('waypoint.action-config', 10, 'random', {
        namespace,
      }),
    }),
    server.create('waypoint.application-template', 'cat-blog', {
      namespace,
      name: 'cat-blog-no-version',
      moduleSource: tfModules.catBlog[0].source,
      terraformCloudWorkspaceDetails:
        faker.helpers.objectValue(terraformProjects),
      // to show pagination on assign actions to template
      actionConfigs: server.createList('waypoint.action-config', 10, 'random', {
        namespace,
      }),
    }),
    server.create(
      'waypoint.application-template',
      'go-and-postgres',
      'with-set-input-vars',
      {
        namespace,
        terraformNocodeModule: tfModules.goAndPostgres[0],
        terraformCloudWorkspaceDetails:
          faker.helpers.objectValue(terraformProjects),
      }
    ),
    server.create(
      'waypoint.application-template',
      'ruby-on-rails',
      'with-set-input-vars',
      {
        namespace,
        terraformNocodeModule: tfModules.rubyOnRails[0],
        terraformCloudWorkspaceDetails:
          faker.helpers.objectValue(terraformProjects),
      }
    ),
    server.create(
      'waypoint.application-template',
      'testing',
      'outOfSyncVars',
      'with-set-input-vars',
      {
        namespace,
        terraformNocodeModule: tfModules.rubyOnRails[0],
        terraformCloudWorkspaceDetails:
          faker.helpers.objectValue(terraformProjects),
      }
    ),
    server.create(
      'waypoint.application-template',
      'nodejs',
      'with-set-input-vars',
      {
        namespace,
        terraformNocodeModule: tfModules.nodeJs[0],
        terraformCloudWorkspaceDetails:
          faker.helpers.objectValue(terraformProjects),
      }
    ),
  ];

  // make first 3 input vars of cat-blog template user editable
  const catBlogTemplate = templates[0];
  catBlogTemplate.update({
    variableOptions: tfModules.catBlog[0].tfModuleDetails.variables.models.map(
      (variable, index) => {
        const {
          id: _id,
          ownerId: _ownerId,
          variableType,
          options,
          userEditable,
          ...newAttrs
        } = variable.attrs;

        const selectedOption = options.length
          ? faker.helpers.arrayElement(options)
          : generateVariableOptionValue(variableType);

        return server.create('waypoint.tf-module-variable', {
          owner: catBlogTemplate,
          variableType,
          ...(index <= 2 ? { userEditable: true } : {}),
          ...(index <= 2 ? { options } : { options: [selectedOption] }),
          ...newAttrs,
        });
      }
    ),
  });

  // Applications
  const applications = Array(12)
    .fill()
    .map(() =>
      server.create(
        'waypoint.application',
        faker.helpers.weightedArrayElement([
          {
            value: `will-${HashicorpCloudWaypointTerraformTFRunState.SUCCESS}`,
            weight: 7,
          },
          {
            value: `will-${HashicorpCloudWaypointTerraformTFRunState.RUNNING}`,
            weight: 1,
          },
          {
            value: `will-${HashicorpCloudWaypointTerraformTFRunState.UNKNOWN}`,
            weight: 1,
          },
          {
            value: `will-${HashicorpCloudWaypointTerraformTFRunState.ERROR}`,
            weight: 1,
          },
        ]),
        {
          namespace,
          applicationTemplate: () => faker.helpers.arrayElement(templates),
        }
      )
    );

  const upgradeTemplate = templates[0];
  const upgradeApp = server.create(
    'waypoint.application',
    'withUpgradeAvailable',
    'withOutputValues',
    {
      namespace,
      name: 'upgrade-me-seymour',
      applicationTemplate: upgradeTemplate,
    }
  );

  upgradeTemplate.variableOptions.models.forEach((v, i) => {
    upgradeApp.createInputVariable({
      name: v.name,
      value: i == 0 ? 'old-value' : v.options[0] ?? '123',
    });
  });

  upgradeApp.createInputVariable({
    name: 'old-var',
    value: 'old-value',
  });

  const application = applications.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    return nameA.localeCompare(nameB);
  })[0];

  const addOnDefinition = server.create('waypoint.add-on-definition', 'redis', {
    namespace,
    terraformNocodeModule: server.create('waypoint.tf-module', {
      nocodeModuleDefinition: nocodeModuleDefinitions.redis,
    }),
    terraformCloudWorkspaceDetails: terraformProjects.default,
  });
  // make first 2 input vars of redis add-on definition user editable
  addOnDefinition.update({
    variableOptions:
      addOnDefinition.terraformNocodeModule.tfModuleDetails.variables.models.map(
        (variable, index) => {
          const {
            id: _id,
            ownerId: _ownerId,
            variableType,
            options,
            userEditable,
            ...newAttrs
          } = variable.attrs;

          const selectedOption = options.length
            ? faker.helpers.arrayElement(options)
            : generateVariableOptionValue(variableType);

          return server.create('waypoint.tf-module-variable', {
            owner: addOnDefinition,
            variableType,
            ...(index < 2 ? { userEditable: true } : {}),
            ...(index < 2 ? { options } : { options: [selectedOption] }),
            ...newAttrs,
          });
        }
      ),
  });

  const addOnDefinitionTest = server.create(
    'waypoint.add-on-definition',
    'redis',
    'outOfSyncVars',
    {
      name: 'redis2',
      namespace,
      terraformNocodeModule: server.create('waypoint.tf-module', {
        nocodeModuleDefinition: nocodeModuleDefinitions.redis,
      }),
      terraformCloudWorkspaceDetails: terraformProjects.default,
    }
  );

  for (const status in HashicorpCloudWaypointTerraformTFRunState) {
    server.create(
      'waypoint.add-on',
      status,
      'withInputVariables',
      status === HashicorpCloudWaypointTerraformTFRunState.SUCCESS
        ? 'withOutputValues'
        : '',
      {
        application,
        definition: addOnDefinition,
      }
    );
  }

  createOrUpdateTFRunState(
    server.schema,
    namespace.id,
    application,
    HashicorpCloudWaypointTerraformTFRunState.SUCCESS,
    8000
  );

  setTimeout(() => {
    // demonstrate apps polling
    server.create(
      'waypoint.application',
      `will-${HashicorpCloudWaypointTerraformTFRunState.SUCCESS}`,
      {
        namespace,
        applicationTemplate: () => faker.helpers.arrayElement(templates),
      }
    );
  }, 8000);

  // Actions
  const actionConfig = server.create('waypoint.action-config', 'promote', {
    namespace,
    applications: () => faker.helpers.arrayElements(applications, 10),
    applicationTemplates: templates,
  });
  server.create('waypoint.action-config', 'deploy', {
    namespace,
    applications,
    applicationTemplates: templates,
  });
  server.create('waypoint.action-config', 'rollback', {
    namespace,
    applications,
  });
  server.create('waypoint.action-run', JobStatus.UNSPECIFIED, {
    namespace,
    actionConfig,
  });
  server.create('waypoint.action-run', JobStatus.UNKNOWN, 'withLogs', {
    namespace,
    actionConfig,
  });
  server.create('waypoint.action-run', JobStatus.RUNNING, 'withLogs', {
    namespace,
    actionConfig,
  });
  server.create('waypoint.action-run', JobStatus.HALTED, 'withLogs', {
    namespace,
    actionConfig,
  });
  server.create('waypoint.action-run', JobStatus.SUCCESS, 'withLogs', {
    namespace,
    actionConfig,
  });
  server.create('waypoint.action-run', JobStatus.ERRORED, 'withLogs', {
    namespace,
    actionConfig,
  });
  server.createList('waypoint.variable', 2, {
    namespace,
    actionConfig,
    overridable: true,
    scope: {
      action: {
        name: actionConfig.name,
      },
    },
  });
  server.create('waypoint.variable', {
    namespace,
    actionConfig,
    overridable: true,
    sensitive: true,
    scope: {
      action: {
        name: actionConfig.name,
      },
    },
  });
  server.create('waypoint.variable', {
    namespace,
    actionConfig,
    sensitive: true,
    scope: {
      action: {
        name: actionConfig.name,
      },
    },
  });
  server.create('waypoint.variable', {
    namespace,
    actionConfig,
    key: 'application.outputs.url',
    value: 'https://example.com',
    scope: {
      action: {
        name: actionConfig.name,
      },
    },
  });
  server.create('waypoint.variable', {
    namespace,
    actionConfig,
    key: 'action.name',
    value: actionConfig.name,
    scope: {
      action: {
        name: actionConfig.name,
      },
    },
  });

  for (const app of [applications[0]]) {
    for (const actionConfig of app.actionConfigs.models) {
      server.create('waypoint.action-run', JobStatus.ERRORED, 'isPast', {
        namespace,
        actionConfig,
        scope: {
          application: {
            id: app.id,
            name: app.name,
          },
        },
      });
      server.create('waypoint.action-run', JobStatus.RUNNING, 'isPast', {
        namespace,
        actionConfig,
        scope: {
          application: {
            id: app.id,
            name: app.name,
          },
        },
      });
      server.createList(
        'waypoint.action-run',
        5,
        JobStatus.SUCCESS,
        'isPast',
        'withLogs',
        {
          namespace,
          actionConfig,
          scope: {
            application: {
              id: app.id,
              name: app.name,
            },
          },
        }
      );
    }
  }

  // Agents

  server.create('waypoint.agent-group', 'prod', { namespace });
  server.create('waypoint.agent-group', 'dev', { namespace });
}
