import { faker } from '@faker-js/faker';

import {
  HashicorpCloudResourcemanagerPolicyBindingMemberType,
  ProjectServiceGetProjectsCountScopeTypeEnum,
} from '@clients/cloud-resource-manager';

import createRoles from '../helpers/role-assignments/create-roles';

// Api role data files
import resourceManagerOrganizationRoles from '../helpers/role-assignments/resource-manager-organization-roles';
import resourceManagerProjectRoles from '../helpers/role-assignments/resource-manager-project-roles';
import iamGroupRoles from '../helpers/role-assignments/iam-group-roles';
import testResourceAppRoles from '../helpers/role-assignments/test-resource-app-roles';

export const HOGWARTS = {
  ORGANIZATION_ID: '4988ada2-cb2c-4ca5-9e44-e129da0e1cb8',
  ORGANIZATION_NAME: 'Hogwarts School of Witchcraft and Wizardry',
};
export const ORGANIZATIONS = {
  HOGWARTS,
};
export const USERS = {
  DUMBLEDORE: {
    id: '5555f5f5-8c88-4275-85fb-b8ad856717cb',
    email: 'albus@hogwarts.edu',
    full_name: 'Albus Dumbledore',
  },
  HARRY_POTTER: {
    id: '6666f5f5-8c88-4275-85fb-b8ad856717cb',
    email: 'harry@hogwarts.edu',
    full_name: 'Harry Potter',
  },
  DRACO_MALFOY: {
    id: '7777f5f5-8c88-4275-85fb-b8ad856717cb',
    email: 'draco@hogwarts.edu',
    full_name: 'Draco Malfoy',
  },
  TOM_RIDDLE: {
    id: '8888f5f5-8c88-4275-85fb-b8ad856717cb',
    email: 'voldemort@horcrux.net',
    full_name: 'Tom Riddle',
  },
};
export const GROUPS = {
  SLYTHERIN: {
    display_name: 'Slytherin 🐍',
    resource_name: `iam/organization/${HOGWARTS.ORGANIZATION_ID}/group/Slytherin`,
    resource_id: 'iam.group:iloveSnakes420',
    description: 'We sure love snakes and potions',
    member_principal_ids: [USERS.DRACO_MALFOY.id, USERS.TOM_RIDDLE.id],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  HUFFLEPUFF_COMMON_ROOM: {
    display_name: 'Hufflepuff common room',
    resource_name: `iam/organization/${HOGWARTS.ORGANIZATION_ID}/group/hufflepuff-common-room`,
    resource_id: 'iam.group:AntisocialEmptyGroup123',
    description: 'Is anyone even in here? Hello?',
    member_principal_ids: [], // This is an intentionally empty group. Do not add principals.
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
};

export const PROJECTS = {
  GRYFFINDOR_QUIDDICH: {
    id: '97cff5f5-8c88-4275-85fb-b8ad856717cf',
    name: 'Gryffindor Quidditch Team',
  },
  CHAMBER_OF_SECRETS: {
    id: '97cff5f5-8e88-4975-85fa-b8ad456717cb',
    name: 'Chamber of Secrets',
  },
};

export const SERVICE_PRINCIPALS = {
  SORTING_HAT: {
    id: 'sorting-hat@123e4567-e89b-12d3-a456-42661417400H4T',
    name: 'Sorting Hat',
    organizationId: 'hogwarts-organization-0001',
    created_at: new Date('1000-01-01T00:00:00Z').toISOString(), // A very old date for humor
    projectId: PROJECTS.GRYFFINDOR_QUIDDICH.id,
  },
  DEMENTOR_00: {
    id: `org-dementor-00@${HOGWARTS.ORGANIZATION_ID}`,
    name: 'Org Dementor 00',
    organizationId: HOGWARTS.ORGANIZATION_ID,
  },
  DEMENTOR_01: {
    id: `project-dementor-01@restricted-and-unattached-project-id-do-not-change`,
    name: 'Project Dementor 01',
    projectId: 'restricted-and-unattached-project-id-do-not-change',
  },
  DEMENTOR_02: {
    id: `project-dementor-02@${PROJECTS.GRYFFINDOR_QUIDDICH.id}`,
    name: 'Project Dementor 02',
    projectId: PROJECTS.GRYFFINDOR_QUIDDICH.id,
  },
};

export default function (server) {
  // Create user principals
  server.create('iam.user-principal', {
    ...USERS.DUMBLEDORE,
    current_user: true,
  });
  server.create('iam.user-principal', {
    ...USERS.HARRY_POTTER,
    current_user: false,
  });
  server.create('iam.user-principal', {
    ...USERS.DRACO_MALFOY,
    current_user: false,
  });
  server.create('iam.user-principal', {
    ...USERS.TOM_RIDDLE,
    current_user: false,
  });

  const dumbledoreUser = server.schema.findBy('iam.user-principal', {
    id: USERS.DUMBLEDORE.id,
  });
  const harryPotterUser = server.schema.findBy('iam.user-principal', {
    id: USERS.HARRY_POTTER.id,
  });
  const dracoMalfoyUser = server.schema.findBy('iam.user-principal', {
    id: USERS.DRACO_MALFOY.id,
  });
  const voldemortUser = server.schema.findBy('iam.user-principal', {
    id: USERS.TOM_RIDDLE.id,
  });
  const slytherinGroup = server.create('iam.group', {
    ...GROUPS.SLYTHERIN,
  });
  const hufflePuffCommonRoomGroup = server.create('iam.group', {
    ...GROUPS.HUFFLEPUFF_COMMON_ROOM,
  });

  // Create organization, projects, and memberships
  server.create('resource-manager.organization', {
    id: HOGWARTS.ORGANIZATION_ID,
    name: HOGWARTS.ORGANIZATION_NAME,
    owner: dumbledoreUser,
  });

  const hogwartsOrganization = server.schema.findBy(
    'resource-manager.organization',
    {
      id: HOGWARTS.ORGANIZATION_ID,
    }
  );

  server.create('resource-manager.project', {
    id: PROJECTS.GRYFFINDOR_QUIDDICH.id,
    parent: {
      type: ProjectServiceGetProjectsCountScopeTypeEnum.ORGANIZATION,
      id: hogwartsOrganization.id,
    },
    name: PROJECTS.GRYFFINDOR_QUIDDICH.name,
    description: 'An initial project',
  });

  server.create('resource-manager.project', {
    id: PROJECTS.CHAMBER_OF_SECRETS.id,
    parent: {
      type: ProjectServiceGetProjectsCountScopeTypeEnum.ORGANIZATION,
      id: hogwartsOrganization.id,
    },
    name: PROJECTS.CHAMBER_OF_SECRETS.name,
    description: 'An initial project',
  });

  const gryffindorQuiddichTeamProject = server.schema.findBy(
    'resource-manager.project',
    {
      id: PROJECTS.GRYFFINDOR_QUIDDICH.id,
    }
  );

  const chamberOfSecretsProject = server.schema.findBy(
    'resource-manager.project',
    {
      id: PROJECTS.CHAMBER_OF_SECRETS.id,
    }
  );

  server.create('iam.organization-membership', {
    created_at: '2024-09-01T22:06:17.324Z',
    organization: hogwartsOrganization,
    principal: dumbledoreUser,
  });
  server.create('iam.organization-membership', {
    created_at: '2024-09-01T22:06:17.324Z',
    organization: hogwartsOrganization,
    principal: harryPotterUser,
  });
  server.create('iam.organization-membership', {
    created_at: '2024-09-01T22:06:17.324Z',
    organization: hogwartsOrganization,
    principal: dracoMalfoyUser,
  });
  server.create('iam.organization-membership', {
    created_at: '2024-09-01T22:06:17.324Z',
    organization: hogwartsOrganization,
    principal: voldemortUser,
  });

  // Service principals
  const sortingHatServicePrincipal = server.create(
    'service-principal.service-principal',
    {
      ...SERVICE_PRINCIPALS.SORTING_HAT,
    }
  );
  const dementor01ServicePrincipal = server.create(
    'service-principal.service-principal',
    {
      ...SERVICE_PRINCIPALS.DEMENTOR_00,
    }
  );

  const dementor02ServicePrincipal = server.create(
    'service-principal.service-principal',
    {
      ...SERVICE_PRINCIPALS.DEMENTOR_01,
    }
  );

  const dementor03ServicePrincipal = server.create(
    'service-principal.service-principal',
    {
      ...SERVICE_PRINCIPALS.DEMENTOR_02,
    }
  );

  // Policies
  server.create('resource-manager.organization.policy', {
    organization: hogwartsOrganization,
    policy: {
      bindings: [
        {
          role_id: 'roles/owner',
          members: [
            {
              member_id: dumbledoreUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        { role_id: 'roles/admin', members: [] },
        {
          role_id: 'roles/contributor',
          members: [
            {
              member_id: harryPotterUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        {
          role_id: 'roles/viewer',
          members: [
            {
              member_id: dracoMalfoyUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
            {
              member_id: slytherinGroup.resource_id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP,
            },
            {
              member_id: sortingHatServicePrincipal.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.SERVICEPRINCIPAL,
            },
          ],
        },
      ],
      etag: faker.string.alphanumeric(51),
    },
  });

  server.create('resource-manager.project.policy', {
    project: gryffindorQuiddichTeamProject,
    policy: {
      bindings: [
        {
          role_id: 'roles/admin',
          members: [
            {
              member_id: harryPotterUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
      ],
      etag: faker.string.alphanumeric(51),
    },
  });

  server.create('resource-manager.project.policy', {
    project: chamberOfSecretsProject,
    policy: {
      bindings: [
        {
          role_id: 'roles/admin',
          members: [
            {
              member_id: voldemortUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        {
          role_id: 'roles/viewer',
          members: [
            {
              member_id: harryPotterUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
            {
              member_id: slytherinGroup.resource_id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP,
            },
          ],
        },
      ],
      etag: faker.string.alphanumeric(51),
    },
  });

  const resource = server.create('resource-manager.resourceV2', {
    resource_name: `test-resource/project/${gryffindorQuiddichTeamProject.id}/app/test-id`,
    resource_id: 'test-id',
  });

  server.create('resource-manager.resource.policy', {
    project: gryffindorQuiddichTeamProject,
    $resource_name: resource.resource_name,
    policy: {
      bindings: [
        {
          role_id: 'roles/admin',
          members: [
            {
              member_id: dumbledoreUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        {
          role_id: 'roles/contributor',
          members: [
            {
              member_id: harryPotterUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
        {
          role_id: 'roles/viewer',
          members: [
            {
              member_id: dracoMalfoyUser.id,
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
            },
          ],
        },
      ],
      etag: faker.string.alphanumeric(51),
    },
  });

  // Groups are resources so the IAM policies are resource-typed.
  server.create('resource-manager.resource.policy', {
    $resource_name: GROUPS.SLYTHERIN.resource_name,
    policy: {
      bindings: [
        {
          role_id: 'roles/iam.group-manager',
          // assign a group manager on group
          members: [
            {
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
              member_id: dracoMalfoyUser.id,
            },
            {
              member_type:
                HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
              member_id: voldemortUser.id,
            },
          ],
        },
      ],
      etag: faker.string.alphanumeric(51),
    },
  });

  // Register roles from real api data that's localized as json files. This
  // section is used to seed role data and attach it to resources so that we can
  // generate exact data for generating the form data but also for generating
  // the correct mapping for test-iam-permissions endpoint.
  createRoles(
    server,
    'role.role.resource-manager-organization',
    resourceManagerOrganizationRoles
  );
  createRoles(
    server,
    'role.role.resource-manager-project',
    resourceManagerProjectRoles
  );
  createRoles(server, 'role.role.iam-group', iamGroupRoles);

  // This is an example of a fake resource being set up to allow for permission
  // registration and is only used in tests.
  createRoles(server, 'role.role.test-resource-app', testResourceAppRoles);
}
