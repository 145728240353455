import { inject as service } from '@ember/service';
import {
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_GET,
} from '../utils/permission-types/index';
import { variation } from 'ember-launch-darkly';

import BaseAbility from './base-ability';
import { TYPE_PROJECT } from 'common/utils/cloud-resource-types';
import {
  PREFIX_RESOURCE_MANAGER_PROJECTS,
  PREFIX_RESOURCE_MANAGER_RESOURCES,
  ACTION_GET_IAM_POLICY,
  ACTION_LIST_ROLES,
  ACTION_SET_IAM_POLICY,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = PREFIX_RESOURCE_MANAGER_PROJECTS;

  @service abilities;
  @service quota;

  /**
   * The ability ensures that the
   * user has permissions to create projects.
   *
   * @param {Array} model - a list of projects.
   * @returns {Boolean}
   */
  get canCreate() {
    let model = this.model || [];
    return (
      model.length < this.quota.for(TYPE_PROJECT) &&
      this.abilities.can('assign-project billing-account') &&
      this.permissions.has(this.generate(ACTION_CREATE))
    );
  }

  /**
   * The ability ensures that the the the target project has no active resources.
   * Resources are not actively returned when fetching a project. An additional
   * call needs to be made to retrieve and set them to the key "resources" in order
   * for an accurate check to be returned.
   *
   * @param {Array} model - a list of active resources belonging to a project.
   * @returns {Boolean}
   */
  get canDelete() {
    const project = this.model;
    return (
      Array.isArray(project?.resources) &&
      project?.resources.length === 0 &&
      this.permissions.has(this.generate(ACTION_DELETE))
    );
  }

  /**
   * The ability checks that the organization has more than one project. This
   * is necessary because we want to allow the user to open the modal that would
   * render a message alerting them that they have resources that need to be
   * removed. The base canDelete method checks the resources so we cannot use it
   * in the dropdown.
   *
   * @param {Array} model - a list of projects belonging to an organization.
   * @returns {Boolean}
   */
  get canOpenDeleteModal() {
    const projects = this.model || [];
    return (
      variation('hcp-project-delete-enabled') &&
      projects.length > 1 &&
      this.permissions.has(this.generate(ACTION_DELETE))
    );
  }

  /**
   * All users of an organization should be able to list projects.
   *
   * @returns {Boolean}
   */
  get canList() {
    return this.permissions.has(this.generate(ACTION_GET));
  }

  /**
   * We need to check project-specific scoped permissions here because the
   * resources.list-roles permission can apply to a project or to specific
   * resources. By checking the scoped permission, we can ensure the project
   * has access to list roles.
   *
   * @returns {Boolean}
   */
  get canListRoles() {
    return this.permissions.scopedPermissions.project.includes(
      `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST_ROLES}`,
    );
  }

  get canGetIamPolicy() {
    return this.permissions.has(this.generate(ACTION_GET_IAM_POLICY));
  }

  get canSetIamPolicy() {
    return this.permissions.has(this.generate(ACTION_SET_IAM_POLICY));
  }

  get canViewRoleAssignments() {
    return this.canGetIamPolicy && this.canListRoles;
  }
}
