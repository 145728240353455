import { faker } from '@faker-js/faker';
import { DateTime } from 'luxon';
import {
  TYPE_BOUNDARY,
  TYPE_CONSUL,
  TYPE_VAULT,
  TYPE_TERRAFORM,
  TYPE_PACKER,
  TYPE_TERRAFORM_ORGANIZATION,
  TYPE_VAULT_SECRETS_PROJECT,
} from 'common/utils/cloud-resource-types';
import {
  UNIT_DISPLAY_KEYS,
  FLEX_CLUSTER_TIER_SIZE,
  PRICING_MODEL,
} from '../consts/billing';
import { Billing20201105Geo } from '@clients/cloud-billing';

export const tfcOrgID = 'terraform-org';

const resourceLineItems = {
  [TYPE_BOUNDARY]: {
    resourceLink: {
      id: 'boundary-cluster',
      description: 'HashiCorp Cloud Boundary instance ("boundary-cluster")',
    },
    lineItems: [
      {
        description: 'Sessions',
        unit_display_key: UNIT_DISPLAY_KEYS.session,
      },
    ],
  },
  [TYPE_CONSUL]: {
    resourceLink: {
      id: 'consul-cluster',
      description: 'HashiCorp Cloud Consul instance ("consul-cluster")',
    },
    lineItems: [
      {
        description: 'Total service instance hours',
        unit_display_key: UNIT_DISPLAY_KEYS.hour,
        average_unit_display_key: 'service_instance_per_hour',
      },
      {
        description: faker.helpers.arrayElement(FLEX_CLUSTER_TIER_SIZE),
        unit_display_key: UNIT_DISPLAY_KEYS.hour,
      },
    ],
  },
  [TYPE_PACKER]: {
    resourceLink: {
      id: 'packer-registry',
      description: 'HashiCorp Cloud Packer registry ("packer-registry")',
    },
    lineItems: [
      {
        description: 'Image hours (Standard)',
        unit_display_key: UNIT_DISPLAY_KEYS.hour,
        average_unit_display_key: 'image_per_hour',
      },
      {
        description: 'Provision requests (Standard)',
        unit_display_key: UNIT_DISPLAY_KEYS.request,
      },
    ],
  },
  [TYPE_TERRAFORM]: {
    resourceLink: {
      description: `HashiCorp Terraform Cloud Payer Account ("${tfcOrgID}")`,
      id: tfcOrgID,
    },
    lineItems: [
      {
        description: 'Admin hours',
        unit_display_key: UNIT_DISPLAY_KEYS.hour,
        average_unit_display_key: 'admin_per_hour',
      },
      {
        description: 'Applies',
        unit_display_key: UNIT_DISPLAY_KEYS.apply,
      },
      {
        description: 'Concurrency hours',
        unit_display_key: UNIT_DISPLAY_KEYS.hour,
        average_unit_display_key: 'concurrency_per_hour',
      },
    ],
  },
  [TYPE_TERRAFORM_ORGANIZATION]: {
    resourceLink: {
      description: tfcOrgID,
      id: tfcOrgID,
    },
    lineItems: [
      {
        description: 'Total Managed Resource hours (Standard)',
        unit_display_key: UNIT_DISPLAY_KEYS.hour,
        average_unit_display_key: 'tfc_resources_per_hour',
      },
    ],
  },
  [TYPE_VAULT]: {
    resourceLink: {
      id: 'vault-cluster',
      description: 'HashiCorp Cloud Vault instance ("vault-cluster")',
    },
    lineItems: [
      {
        description: 'Total clients',
        unit_display_key: UNIT_DISPLAY_KEYS.client,
      },
      {
        description: faker.helpers.arrayElement(FLEX_CLUSTER_TIER_SIZE),
        unit_display_key: UNIT_DISPLAY_KEYS.hour,
      },
    ],
  },
  [TYPE_VAULT_SECRETS_PROJECT]: {
    resourceLink: {
      id: 'Vault Secrets',
      description: 'Vault Secrets Project',
    },
    lineItems: [
      {
        description: 'Secret hours (Standard)',
        unit_display_key: UNIT_DISPLAY_KEYS.hour,
        average_unit_display_key: 'project_secret_per_hour',
      },
      {
        description: 'API operations (Standard)',
        unit_display_key: UNIT_DISPLAY_KEYS.op,
        average_unit_display_key: 'api_operations_per_month',
      },
    ],
  },
};

function generateLineItems(type, entitlement) {
  return type.map((item) => {
    return {
      ...item,
      amount: entitlement ? '0.00' : faker.finance.amount(),
      quantity: faker.number.int({ min: 0, max: 1000 }).toString(),
      average_quantity: item.average_unit_display_key
        ? faker.number.int().toString()
        : '',
      unit_price: faker.number.float({
        min: 0,
        max: 1,
        precision: 0.0000001,
      }),
      started_at: DateTime.utc().startOf('month').toString(),
      ended_at: DateTime.utc().toString(),
    };
  });
}

export function generateEdition(type) {
  return type === TYPE_TERRAFORM
    ? 'business'
    : faker.helpers.arrayElement(['standard', 'plus']);
}

export function generateResource({
  type,
  projectIds,
  organizationId,
  pricingModel,
}) {
  let edition = generateEdition(type);
  let entitlement = pricingModel === PRICING_MODEL.ENTITLEMENT;
  let isFlex = pricingModel === PRICING_MODEL.FLEX;

  let randomProjectId =
    projectIds && projectIds[Math.floor(Math.random() * projectIds.length)];

  return {
    amount: entitlement ? '0.00' : faker.finance.amount(),
    // only return flexible_consumption_metadata and support level
    // for a resource if the account is on Flex
    flexible_consumption_metadata: isFlex
      ? {
          support_level: faker.helpers.arrayElement([
            'bronze',
            'silver',
            'gold',
          ]),
        }
      : null,
    resource_link: {
      ...resourceLineItems[type].resourceLink,
      location: {
        // if id's are passed in, one will be assigned to the resource otherwise a random id will be generated
        organization_id: organizationId || faker.string.uuid(),
        project_id: randomProjectId || faker.string.uuid(),
        region:
          TYPE_CONSUL || TYPE_VAULT || TYPE_BOUNDARY
            ? {
                region: 'us-west-2',
                provider: 'aws',
              }
            : null,
      },
      type: type,
      uuid: faker.string.uuid(),
    },
    active_from: faker.date.past().toISOString(),
    active_until: faker.helpers.arrayElement([
      null,
      faker.date.between({
        from: DateTime.utc().startOf('month').toString(),
        to: DateTime.utc().toString(),
      }),
    ]),
    line_items: generateLineItems(
      resourceLineItems[type].lineItems,
      entitlement
    ),
    geo: faker.helpers.arrayElement([
      Billing20201105Geo.US,
      Billing20201105Geo.EU,
    ]),
    // An empty object is assigned to common_resource_attributes for
    // older statements. For newer statements where the
    // common_resource_attributes object contains properties, 'edition'
    // is always present and should always return a value (not null).
    common_resource_attributes: faker.helpers.arrayElement([
      {
        geo: faker.helpers.arrayElement(['us', 'eu', null]),
        edition,
      },
      {},
    ]),
  };
}

export function generateMidMonthAddonUsage({ projectIds, organizationId }) {
  // must be of same type, but can be random
  let type = faker.helpers.arrayElement([
    TYPE_BOUNDARY,
    TYPE_CONSUL,
    TYPE_PACKER,
    TYPE_TERRAFORM,
    TYPE_TERRAFORM_ORGANIZATION,
    TYPE_VAULT,
    TYPE_VAULT_SECRETS_PROJECT,
  ]);

  //must have same uuid
  let uuid = faker.string.uuid();
  // date when mid-month addon happened
  let addOnDate = faker.date.recent().toString();

  let beforeAddOn = generateResourceUsage({
    type,
    projectIds,
    organizationId,
  });
  beforeAddOn.active_from = DateTime.utc().startOf('month').toString();
  beforeAddOn.active_until = addOnDate;
  beforeAddOn.uuid = uuid;
  beforeAddOn.flexible_consumption_metadata.support_level = 'silver';

  let afterAddOn = generateResourceUsage({
    type,
    projectIds,
    organizationId,
  });
  afterAddOn.active_from = addOnDate;
  afterAddOn.active_until = null;
  afterAddOn.uuid = uuid;
  afterAddOn.flexible_consumption_metadata.support_level = 'gold';

  let resources = generateResourceUsage();
  resources.push(beforeAddOn, afterAddOn);

  return resources;
}

export function generateResourceUsage({
  type,
  projectIds,
  organizationId,
  pricingModel,
} = {}) {
  // generate specific resource usage
  if (type) {
    return generateResource({ type, projectIds, organizationId, pricingModel });
  } else {
    return faker.helpers
      .arrayElements([
        TYPE_BOUNDARY,
        TYPE_CONSUL,
        TYPE_PACKER,
        TYPE_TERRAFORM,
        TYPE_TERRAFORM_ORGANIZATION,
        TYPE_VAULT,
        TYPE_VAULT_SECRETS_PROJECT,
      ])
      .map((item) => {
        return generateResource({
          type: item,
          projectIds,
          organizationId,
          pricingModel,
        });
      });
  }
}
